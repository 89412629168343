const RENDER_RUNTIME = '__RENDER_8_RUNTIME__'
const RENDER_COMPONENTS = '__RENDER_8_COMPONENTS__'
const RENDER_HOT = '__RENDER_8_HOT__'
const RENDER_LAZY = '__RENDER_LAZY__'

const isBrowser = !!(typeof window !== 'undefined' && window.document)
if (isBrowser && !window.global) {
  window.global = window // compatibility
}

window[RENDER_COMPONENTS] = window[RENDER_COMPONENTS] || {}
if (module.hot) {
  window[RENDER_HOT] = window[RENDER_HOT] || {}
}

function tryLoadModule(requireReactEntrypoint, componentId) {
  try {
    const m = requireReactEntrypoint()
    return m.__esModule ? m.default : m.default || m
  } catch (error) {
    throw new Error(
      `An error happened while requiring the app ${componentId}, please check your app's code.\\n${error.stack}`
    )
  }
}

export function register(requireReactEntrypoint, renderEntrypointModule, app, appAtMajor, name) {
  const componentId = `${app}/${name}`
  const majorComponentId = `${appAtMajor}/${name}`

  const lazyLoad = () => tryLoadModule(requireReactEntrypoint, componentId)
  if (window[RENDER_RUNTIME] && window[RENDER_RUNTIME].registerComponent) {
    const lazy = !!window[RENDER_LAZY]
    window[RENDER_RUNTIME].registerComponent(renderEntrypointModule, lazy ? lazyLoad : lazyLoad(), app, name, lazy)
  } else {
    const loadedModule = lazyLoad()
    if (window[RENDER_RUNTIME] && window[RENDER_RUNTIME].withHMR) {
      window[RENDER_COMPONENTS][componentId] = window[RENDER_COMPONENTS][majorComponentId] = window[
        RENDER_RUNTIME
      ].withHMR(renderEntrypointModule, loadedModule)
    } else {
      window[RENDER_COMPONENTS][componentId] = window[RENDER_COMPONENTS][majorComponentId] = loadedModule
    }
  }

  if (module.hot) {
    const renderHotReload =
      window[RENDER_COMPONENTS][componentId] &&
      window[RENDER_COMPONENTS][componentId].hotReload &&
      (() => {
        const loadedModule = tryLoadModule(requireReactEntrypoint, componentId)
        window[RENDER_COMPONENTS][componentId].hotReload(loadedModule)
      })

    const setupHMR =
      isBrowser &&
      ((hotEmitter, hotLog) => {
        window[RENDER_HOT][app] = window[RENDER_HOT][app] || hotEmitter
        hotLog.setLogLevel('error')
      })

    return {
      renderHotReload,
      setupHMR,
    }
  }

  return {}
}

export function getPublicPath(app) {
  let host = window.__hostname__
  let assetsPath = '/_v/public/assets/v1/published/'

  if (isBrowser && window.__RUNTIME__) {
    host =
      (module.hot ? __RUNTIME__.assetServerLinkedHost : __RUNTIME__.assetServerPublishedHost) ||
      window.location.hostname
    assetsPath = module.hot
      ? __RUNTIME__.assetServerLinkedPath || '/_v/private/assets/v1/linked/'
      : __RUNTIME__.assetServerPublishedPath || '/_v/public/assets/v1/published/'
  }

  return 'https://' + host + assetsPath + `${app}/public/react/`
}
